import "./about-us.scss"

import * as React from "react"

import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import loadable from "@loadable/component"

// import Experience from "../components/about-us/Experience"
// import Experts from "../components/about-us/Experts"
// import OurStory from "../components/about-us/OurStory"
// import Understanding from "../components/about-us/Understanding"
// import Banner from "../components/Banner"
// import Slogan from "../components/Slogan"
import Layout from "../components/Layout"
import {
  getAllLocales,
  getBackgroundImage,
  getCurrentLanguagePage,
  getGatsbyImage,
} from "../plugins/common"

const Experience = loadable(() => import("../components/about-us/Experience"))
const Experts = loadable(() => import("../components/about-us/Experts"))
const OurStory = loadable(() => import("../components/about-us/OurStory"))
const Understanding = loadable(() =>
  import("../components/about-us/Understanding")
)
const Banner = loadable(() => import("../components/Banner"))
const Slogan = loadable(() => import("../components/Slogan"))

const AboutUsPage = ({ pageContext, location }) => {
  const { node: data, general, form } = pageContext

  // console.log(data)
  const aleaToken = general.aleaToken
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const ogImage = getBackgroundImage(data.headerBlock.image[0])
  const headerImage = getGatsbyImage(data.headerBlock.image[0])
  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...form.freeQuote,
  }
  const copper = "../images/about-us/slogan/copper.webp"
  const line = "../images/about-us/slogan/line.webp"
  return (
    <Layout
      props={general}
      meta={data.meta}
      localizations={getAllLocales(data.localizations, data.locale)}
      location={location}
      lang={lang}
      ogImage={ogImage}
      hideNewsletter={true}
      token={aleaToken}
      footerForm={enableFooterForm ? footerForm : null}
    >
      <div className="overflow-hidden aboutUs">
        {/* <Slogan props={data.headerBlock} lang={lang} /> */}
        <Slogan props={data.headerBlock} lang={lang}>
          <div id="right" className="aboutUsHeader position-relative">
            <div className="imageZoom">
              <GatsbyImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="image imageAni position-absolute"
                image={headerImage}
                alt="alea"
              />
            </div>
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className="copper position-absolute"
              src={copper}
              alt="alea"
            />
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className="line position-absolute"
              src={line}
              alt="alea"
            />
          </div>
        </Slogan>
        <OurStory props={data.story} lang={lang} />
        <Understanding props={data.banner1} lang={lang} />
        <Experience props={data.story2} lang={lang} />
        <Experts props={data.ourTeam} lang={lang} />
        <Banner props={data.ctaBanner} lang={lang} />
      </div>
    </Layout>
  )
}

export default AboutUsPage
